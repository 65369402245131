import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";
// import PreviewCompatibleImage from "./PreviewCompatibleImage";

const PostStatusBadge = ({ postStatus }) => {
  const postProperties = {
    comingSoon: {
      postStatusClass: "post-status post-status-coming-soon",
      postStatusText: "Coming soon",
      type: "comingSoon"
    },
    new: {
      postStatusClass: "post-status post-status-new",
      postStatusText: "New!",
      type: "new"
    }
  };

  return (
    <span className={`tag ${postProperties[postStatus].postStatusClass}`}>
      {postProperties[postStatus].postStatusText}
    </span>
  );
};
class BlogRoll extends React.Component {
  render() {
    const { data, postToHide } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="content">
        {posts &&
          posts
            .filter(({ node: post }) => post.id !== postToHide)
            .map(({ node: post }) => (
              <div className="blog-list-item" key={post.id}>
                <article>
                  <header>
                    <p className="post-title post-meta is-size-5">
                      <Link className="has-text-primary" to={post.fields.slug}>
                        {post.frontmatter.title}
                      </Link>
                      <PostStatusBadge
                        postStatus={post.frontmatter.postStatus}
                      />
                    </p>
                  </header>
                  <p />
                  <p className="has-text-indent-2">
                    {post.frontmatter.description}
                  </p>
                  {post.frontmatter.postStatus !== "comingSoon" ? (
                    <Link className="has-text-indent-2" to={post.fields.slug}>
                      Keep Reading →
                    </Link>
                  ) : null}
                </article>
              </div>
            ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export default props => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___order] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                order
                description
                postStatus
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} {...props} />}
  />
);
