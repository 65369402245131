import React from "react";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

import goodTeamSvg from "../../img/undraw_good_team.svg";
export default class BlogIndexPage extends React.Component {
  componentDidMount() {
    window.location.href = "https://www.gued.is/garden";
  }

  render() {
    return (
      <Layout>
        <section
          id="section-blog-introduction"
          className="content profile section has-no-padding-bottom"
        >
          <div className="container">
            <div className="columns">
              <div className="column is-2 is-hidden-mobile" />

              <div id="hero-profile-column" className="column is-4">
                <img
                  src={goodTeamSvg}
                  alt="Cartoon figures working together"
                  height="300px"
                  width="300px"
                />
              </div>
              {/* <div id="hero-column-blurb" className="column is-5"> */}
              <div id="hero-column-blurb" className="column is-4">
                <div id="hero-introduction-blurb">
                  <h2 className="has-text-primary">Redirecting ... </h2>
                  {/* <p className="">
                          Thoughts on community, engineering, and product.
                        </p> */}
                </div>
              </div>
              {/* <div className="column is-2 is-hidden-mobile" /> */}
              <div className="column is-3 is-hidden-mobile" />
            </div>
          </div>
        </section>
      </Layout>
    );
  }

  //   return (
  //     <Layout>
  //       <section
  //         id="section-blog-introduction"
  //         className="content profile section has-no-padding-bottom"
  //       >
  //         <div className="container">
  //           <div className="columns">
  //             <div className="column is-2 is-hidden-mobile" />

  //             <div id="hero-profile-column" className="column is-4">
  //               <img
  //                 src={goodTeamSvg}
  //                 alt="Cartoon figures working together"
  //                 height="300px"
  //                 width="300px"
  //               />
  //             </div>
  //             {/* <div id="hero-column-blurb" className="column is-5"> */}
  //             <div id="hero-column-blurb" className="column is-4">
  //               <div id="hero-introduction-blurb">
  //                 <h2 className="has-text-primary">Latest Stories</h2>
  //                 <p className="">
  //                   Thoughts on community, engineering, and product.
  //                 </p>
  //               </div>
  //             </div>
  //             {/* <div className="column is-2 is-hidden-mobile" /> */}
  //             <div className="column is-3 is-hidden-mobile" />
  //           </div>
  //         </div>
  //       </section>
  //       <section className="section">
  //         <div className="columns">
  //           {/* <div className="column is-2" /> */}
  //           <div className="column is-3" />
  //           {/* <div id="hero-blog-roll-column" className="column is-8"> */}
  //           <div id="hero-blog-roll-column" className="column is-6">
  //             <BlogRoll />
  //           </div>
  //           {/* <div className="column is-2" /> */}
  //           <div className="column is-3" />
  //         </div>
  //       </section>
  //     </Layout>
  //   );
}
